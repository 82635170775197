export default {
    menus: [
        'head',
        'bold',
        'fontSize',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'video',
        'table',
        'splitLine',
        'undo',
        'redo',
    ],
    uploadImgServer:'https://fkandfkltd.bscd8.com/'
}